<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="sign__content">
          <!-- authorization form -->
          <form v-on:submit.prevent="AddUsers()" ref="formContainer" class="vld-parent sign__form">
            <a href="index.php" class="sign__logo">
              <img src="assets/img/SudaniWhiteLogo-NoShadow.png" alt="" />
            </a>
            <span class="sign__delimiter"
                ><h6 style="text-align: center">
                    سوف يتم تجديد الاشتراك تلقائيا
                </h6></span
              >
            <div class="sign__group">
              
              <!-- <span class="sign__delimiter" style="font-size:14px">رقم الهاتف : </span> -->
              <input
                type="phone"
                v-model="phone"
                class="sign__input"
                placeholder="2491XXXXXXXX"
              />
            </div>
            <div class="sign__group">
              <label><input type="checkbox" v-model="fullPage"></label>
              <button class="sign__btn" type="submit">اشتراك</button>
              <!-- <button
                type="submit"
                class="sign__btn"
                onclick="this.classList.toggle('button--loading')"
              >
                <span class="button__text">اشتراك</span>
              </button> -->
              <span class="sign__delimiter"
                ><h6 style="text-align: center">
                  * قيمة الاشتراك اليومي 10 جنيهات شاملة الضريبة
                </h6></span
              >
              <span class="sign__delimiter"
                ><h6 style="text-align: center">
                  * سوف تصلك رسالة نصية تحتوي علي كود التفعيل
                </h6></span
              >
            </div>
          </form>

          <!-- end authorization form -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "Signups",
  data() {
    return {
      fullPage: false,
    };
  },
  computed: {
    phone: {
      get: function () {
        return this.oldNum;
      },
      set: function (newValue) {
        this.oldNum = newValue.replace(/[^\d]/g, "");
      },
    },
  },
  methods: {
    AddUsers() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.oldNum ? null : this.$refs.formContainer,
        // canCancel: true,
        // onCancel: this.onCancel,
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 7000);

      const toast = useToast();
      const str = this.oldNum;

      if (str.substr(0, 1) === "0") {
        let number = str.substr(1);
        if (number.startsWith(1)) {
          this.num = number;
        } else {
          toast.warning("رقم الهاتف ليس سوداني", {
            timeout: 2000,
          });
        }
      } else if (str.substr(0, 1) === "2" && str.length == 12) {
        let number = str.substr(3);
        if (number.startsWith(1)) {
          this.num = number;
        } else {
          toast.warning("رقم الهاتف ليس سوداني", {
            timeout: 2000,
          });
        }
      } else if (str.substr(0, 1) === "1" && str.length == 9) {
        // number = parseInt(str);
        let number = str.substr();
        // console.log(number);
        if (number.startsWith(1)) {
          this.num = number;
        } else {
          toast.warning("رقم الهاتف ليس سوداني", {
            timeout: 2000,
          });
        }
      } else if (str.length != 9) {
        toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", {
          timeout: 2000,
        });
      }

      axios
        .post("SpaySubscribtionRequest.php?msisdn=" + "249" + this.num)
        .then((response) => {
          if (response.data.responseCode == 1 && response.data.error == false) {
            this.$cookie.setCookie("requestId", response.data.requestId, {
              expire: 60 * 5,
            });
            this.$cookie.setCookie("msisdn", response.data.mdn, {
              expire: 60 * 5,
            });
            toast.success("رقم الهاتف صحيح ادخل رمز التاكيد", {
              timeout: 4000,
            });
            setTimeout(() => this.$router.push({ name: "Virefy" }), 4000);
          } else if (
            response.data.error == true &&
            response.data.responseCode == 10
          ) {
            // user active
            this.$cookie.removeCookie("product_code");
            this.$cookie.removeCookie("SUBID");
            toast.info("انت مشترك مسبقا الرجاء تسجيل الدخول", {
              timeout: 4000,
            });
            setTimeout(
              () =>
                this.$router.push({
                  name: "Login",
                }),
              4000
            );
          }else if (
            response.data.error == true &&
            response.data.responseCode == 121
          ) {
            // user active
            toast.info("انت مشترك مسبقا سيتم تسجيل دخولك", {
              timeout: 4000,
            });
            axios.post("CheckLogin.php?username=249"+this.num+"&serviceId=2222&operatorID=2").then((response) => {
          if (response.data.status == 1 && response.data.MINTUES > 0) {
            this.$cookie.setCookie("status", response.data.status, {
              expire: 60 * response.data.MINTUES,
            });
            this.$cookie.setCookie("mdn", response.data.msisdn, {
              expire: 60 * response.data.MINTUES,
            });
            this.$cookie.setCookie("minutes", response.data.MINTUES, {
              expire: 60 * response.data.MINTUES,
            });
            toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
              timeout: 4000,
            });
            setTimeout(() => this.$router.push({ path: "/" }), 4000);
          } else if (response.data.status == 0 && response.data.MINTUES > 0) {
            this.$cookie.setCookie("status", response.data.status, {
              expire: 60 * response.data.MINTUES,
            });
            this.$cookie.setCookie("mdn", response.data.msisdn, {
              expire: 60 * response.data.MINTUES,
            });
            this.$cookie.setCookie("minutes", response.data.MINTUES, {
              expire: 60 * response.data.MINTUES,
            });
            toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
              timeout: 4000,
            });
            setTimeout(() => this.$router.push({ path: "/" }), 4000);
          } else if (response.data.status == 1 && response.data.MINTUES < 0) {
            this.$cookie.setCookie("status", response.data.status, {
              expire: 60 * 120,
            });
            this.$cookie.setCookie("mdn", response.data.msisdn, {
              expire: 60 * 120,
            });
            this.$cookie.setCookie("minutes", response.data.MINTUES, {
              expire: 60 * 120,
            });
            toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
              timeout: 4000,
            });
            setTimeout(() => this.$router.push({ path: "/" }), 4000);
          } else if (response.data.status == 0 && response.data.responseCode==117) {
            
            if (this.$cookie.isCookieAvailable("Content_id")) {
              toast.error("عفواً انت لست مشترك في الخدمة ، الرجاء اضغط علي زر الاشتراك", {
              timeout: 4000,
            });
            this.$router.push({ path: "/Landing" });
            } 
            else{
            toast.error("عفواً انت لست مشترك في الخدمة ، الرجاء اضغط علي زر الاشتراك", {
              timeout: 4000,
            });
            this.$router.push({ path: "/Landing" });
     
            }

            // setTimeout(
            //   () =>
            //     this.$router.push({
            //       path: "/login",
            //     }),
            //   4000
            // );
          }
          else if (response.data.status == 0 && response.data.responseCode==118) {
            
            if (this.$cookie.isCookieAvailable("Content_id")) {
              toast.info("عفواً لم تكتمل عملية الاشتراك ، الرجاء تغذية رصيدك", {
              timeout: 4000,
            });
            this.$router.push({ path: "/" });
            } 
            else{
            toast.info("عفواً لم تكتمل عملية الاشتراك ، الرجاء تغذية رصيدك", {
              timeout: 4000,
            });
            this.$router.push({ path: "/" });
     
            }

            // setTimeout(
            //   () =>
            //     this.$router.push({
            //       path: "/login",
            //     }),
            //   4000
            // );
          }
          
        });
          } else if (
            response.data.error == true &&
            response.data.responseCode == 118
          ) {
            // user not active
            this.$cookie.removeCookie("product_code");
            this.$cookie.removeCookie("SUBID");
            toast.info("لم يتم تجديد اشتراكك بعد", {
              timeout: 4000,
            });
            setTimeout(
              () =>
                this.$router.push({
                  name: "/",
                }),
              4000
            );
          }
          //   else if (response.data.error == true && response.data.responseCode == "" ) {
          //     // user not active
          //     this.$cookie.removeCookie("product_code");
          //     this.$cookie.removeCookie("SUBID");
          //     toast.error("انت مشترك مسبقا الرجاء تسجيل الدخول", {
          //           timeout: 4000
          //        });
          //      setTimeout(
          //        () =>
          //          this.$router.push({
          //             name: "Login",
          //          }),
          //       4000
          //      );
          //   }
          else if (
            response.data.error == true &&
            response.data.responseCode == 106
          ) {
            // user not active
            this.$cookie.removeCookie("product_code");
            this.$cookie.removeCookie("SUBID");
            toast.error("عفوا ادخل رقم هاتف صحيح", {
              timeout: 4000,
            });
          } else {
            toast.error("حدثت مشكلة الرجاء المحاولة مرة اخري", {
              timeout: 4000,
            });
          }
        });
    },
  },
};
</script>

<style >
.button {
  position: relative;
  padding: 8px 16px;
  background: #009579;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.button:active {
  background: #007a63;
}

.button__text {
  font: bold 20px "Quicksand", san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>